'use client';

import useShowIntercomChat from '~/hooks/useShowIntercomChat';

const IntercomChat = () => {
  useShowIntercomChat();

  return null; // No need to render anything for this component
};

export default IntercomChat;
