'use client';
import { useEffect } from 'react';
import { getDeviceTypeClientSide } from '~/utils/clientUtils';

function useHideIntercomChat(hideOnDesktop = false) {
  const { isMobile } = getDeviceTypeClientSide();
  useEffect(() => {
    const shouldHide = isMobile || hideOnDesktop;

    if (shouldHide) {
      const style = document?.createElement('style');
      style.setAttribute('id', 'intercom-chat-hider');

      document.head.appendChild(style);

      style.textContent = `
      .intercom-lightweight-app {
        display: none !important;
        }
        .intercom-container {
          display: none !important;
          }
          .intercom-app {
        display: none !important;
        }
        `;
    }

    return () => {
      if (hideOnDesktop) {
        const styleToRemove = document.getElementById('intercom-chat-hider');
        if (styleToRemove) {
          document.head.removeChild(styleToRemove);
        }
      }
    };
  }, [hideOnDesktop, isMobile]);
}

export default useHideIntercomChat;
