'use client';

import { Badge as ChackraBadge, Flex } from '@chakra-ui/react';
import React from 'react';

import { Icon } from './Icon';

export interface BadgeProps {
  variant: BadgeVariants;
}

export enum BadgeVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Subdued = 'subdued',
}

/**
 * @see Additional styles variations: styles/components/badge.ts
 */
export function Badge({
  style,
  variant,
  value,
  icon,
  colorScheme,
}: {
  variant: string;
  style: {};
  value: string;
  icon?: string;
  colorScheme?: string;
}) {
  return (
    <ChackraBadge style={style} variant={variant} colorScheme={colorScheme}>
      <Flex alignItems={'center'}>
        {icon && <Icon size={16} name={icon} mr={2} />}

        {value}
      </Flex>
    </ChackraBadge>
  );
}
