import { useEffect } from 'react';
import useHideIntercomChat from './useHideIntercomChat';
import { getDeviceTypeClientSide } from '~/utils/clientUtils';

declare global {
  interface Window {
    Intercom?: (cmd: string) => void;
  }
}

function useShowIntercomChat() {
  const { isMobile } = getDeviceTypeClientSide();
  useHideIntercomChat(); // Only for mobile

  useEffect(() => {
    if (!isMobile) {
      // Only for desktop also keep above line to secure twice
      const checkIntercomAndShow = () => {
        if (window.Intercom && typeof window.Intercom === 'function') {
          if (!sessionStorage.getItem('intercomShown')) {
            window.Intercom('show');
            sessionStorage.setItem('intercomShown', 'true');
          }
        } else {
          setTimeout(checkIntercomAndShow, 500);
        }
      };
    }
  }, [isMobile]);
}

export default useShowIntercomChat;
