'use client';

import { useEffect, useState } from 'react';

import Flex from '../flex/Flex';

type ProgressLineProps = {
  progress: number; // Accepts a number from 1 to 4
} & React.HTMLAttributes<HTMLDivElement>;

const ProgressLine = ({ progress, ...props }: ProgressLineProps) => {
  const [animatedProgress, setAnimatedProgress] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      setAnimatedProgress(progress);
    }, 500); // Delay to start the animation
  }, [progress]);

  return (
    <Flex className="items-center justify-between w-full my-2" {...props}>
      {[1, 2, 3, 4].map((index) => (
        <div
          key={index}
          className={`relative h-[1.5px] flex-1 transition-all duration-[2500ms] ease-in-out ${
            index <= animatedProgress ? 'bg-secondary' : 'bg-gray-200'
          }`}
        >
          {/* Spacing between segments */}
          <div
            className={`absolute left-[-1px] top-[-1.5px] w-[5px] h-[5px] rounded-full transform -translate-x-px -translate-y-0.5 ${
              index <= animatedProgress + 1 ? 'bg-secondary' : 'bg-gray-200'
            }`}
          />
          {index === 4 && (
            <div
              className={`absolute top-[-1.5px] right-[-1px] w-[5px] h-[5px] rounded-full transform translate-x-px -translate-y-0.5 ${
                animatedProgress === 4 ? 'bg-secondary' : 'bg-gray-200'
              }`}
            />
          )}
        </div>
      ))}
    </Flex>
  );
};

export default ProgressLine;
